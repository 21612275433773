import Link from "next/link";
import moment from "moment";
import classNames from "classnames";
import LiveBadge from "./LiveBadge";
import Button from "./Button";
import { useState } from "react";
import { generateNuggetsSummary } from "../utils/formatters";
import { leaving_soon_ms } from "../config";

export default function MediaItem({
  item,
  displayMode = "default",
  festivalId,
  colorPalette = "default",
  includeTicketDetails,
  isSingle,
  isGrid,
  isDiscoverySearch,
  isDiscoveryList,
  onHover,
  onClick
}) {
  const {
    id,
    film_id,
    event_id,
    image,
    poster_image,
    cover_image,
    name,
    film_name,
    virtual_content_type,
    coming_soon,
    start_time,
    end_time,
    tenant_name,
    tenant_slug,
    short_description,
    nuggets,
    type
  } = item;

  if (tenant_slug) {
    festivalId = tenant_slug;
  }

  let imageToShow =
    displayMode === "default" || displayMode === "giant_slider"
      ? image
      : displayMode === "poster" || displayMode === "big_poster"
      ? poster_image
      : displayMode === "cover"
      ? cover_image
      : image;

  const now = new Date();
  const start = new Date(start_time);
  const end = new Date(end_time);
  let ending_soon = null;
  if (start_time && end_time) {
    ending_soon = now > start && now < end && end - now <= leaving_soon_ms;
  }
  let liveBadge =
    coming_soon || ending_soon || virtual_content_type === "livestream" ? (
      <LiveBadge
        start_time={start_time}
        end_time={end_time}
        comingSoon={coming_soon}
        verbose={displayMode === "giant_slider"}
        className={classNames(
          displayMode === "giant_slider" ? "" : "absolute right-2 top-2"
        )}
      />
    ) : null;

  if (isDiscoverySearch && type === "channel") {
    liveBadge = (
      <span
        className={classNames(
          "inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium leading-5",
          "absolute right-2 top-2",
          "bg-blue-100 text-blue-800"
        )}
      >
        Channel
      </span>
    );
  }

  let detailPopover = isGrid ? (
    <div
      className={classNames(
        "absolute inset-0 transition duration-150 ease-in-out opacity-0 group-hover:opacity-100 rounded-lg",
        includeTicketDetails && "rounded-b-none"
      )}
      style={{ backgroundColor: "rgba(0,0,0,0.6)" }}
    >
      <div
        className={classNames(
          "text-xs text-always-white p-4 absolute top-0 left-0 right-0 transition-all transform duration-150 ease-in-out group-hover:opacity-100 group-hover:translate-y-0 opacity-0 translate-y-6",
          isDiscoverySearch && type === "channel" && "pr-24"
        )}
      >
        <div
          className={
            displayMode === "big_poster"
              ? "line-clamp-8-lines no-translate"
              : "line-clamp-4-lines no-translate"
          }
        >
          {short_description}
        </div>
        {nuggets ? (
          <div className="text-always-gray-400 truncate no-translate">
            {generateNuggetsSummary(nuggets)}
          </div>
        ) : null}
      </div>
    </div>
  ) : null;

  let inner;
  if (displayMode === "giant_slider") {
    inner = (
      <div
        className={classNames(
          `relative group rounded-lg overflow-hidden`,
          isSingle
            ? ``
            : `sm:rounded-none border-gray-600 sm:border-t-2 sm:border-b-2`
        )}
        style={{ height: 500, maxHeight: "50vw" }}
      >
        <div className="absolute right-0 top-0 bottom-0 sm:left-1/3 left-0 overflow-hidden bg-black">
          <img
            className="object-cover opacity-50 sm:opacity-75 h-full no-translate"
            src={imageToShow}
            alt={name}
          />
          <div className="hidden sm:block slider-prev-background w-96 h-full absolute top-0 left-0" />
        </div>
        <div className="absolute sm:left-24 sm:top-1/5 sm:right-2/5 lg:right-3/5 inset-6 flex flex-col items-center justify-center sm:block">
          <div className="text-always-white sm:text-white text-center sm:text-left transition duration-100 font-bold text-xl sm:text-4xl leading-6 sm:leading-10 no-translate">
            {name}
          </div>
          {short_description ? (
            <div
              className={classNames(
                "hidden md:block mt-4 text-gray-300 transition duration-100 font-medium leading-6 lg:pr-1/6",
                type === "channel" ? "text-md" : "text-lg"
              )}
            >
              <div
                className="line-clamp-8-lines no-translate"
                style={{ maxHeight: "20vw" }}
              >
                {short_description}
              </div>
            </div>
          ) : null}
          {liveBadge ? <div className="mt-2 sm:mt-4">{liveBadge}</div> : null}
          <Button size="huge" className="hidden sm:block mt-6">
            {type === "channel"
              ? "Enter channel"
              : coming_soon
              ? "Pre-order now"
              : "Watch now"}
          </Button>
        </div>
      </div>
    );
  } else if (displayMode === "big_poster") {
    inner = (
      <div className="group">
        <div
          className="relative transition transform group-hover:scale-105 group-hover:bg-gray-500 duration-150 bg-gray-600 rounded-lg"
          style={{ paddingBottom: "150%" }}
        >
          {imageToShow ? (
            <img
              className="absolute rounded-lg object-cover h-full w-full transition duration-150 no-translate"
              src={imageToShow}
              alt={name}
            />
          ) : null}
          {detailPopover}
          {liveBadge}
        </div>
        <div className="transition transform duration-150 group-hover:scale-105 group-hover:translate-y-2 origin-top mt-4 mb-0 sm:mb-4 px-4">
          <div
            className={classNames(
              "font-bold text-center text-lg sm:text-xl transition duration-150 text-gray-200 leading-6 no-translate",
              colorPalette !== "white" && "group-hover:text-white"
            )}
          >
            {name}
          </div>
        </div>
      </div>
    );
  } else {
    inner = (
      <div
        className={classNames(
          "relative cursor-pointer transition z-10 duration-150 flex items-center transform sm:group-hover:scale-105 sm:group-hover:shadow-outline-gray group-hover:z-20 group-hover:shadow-lg rounded-lg",
          type === "channel" && !imageToShow ? "bg-blue-500" : "bg-gray-500",
          displayMode === "default"
            ? "h-40"
            : displayMode === "poster"
            ? "h-60 w-40"
            : displayMode === "cover"
            ? "h-full"
            : "",
          includeTicketDetails && "shadow rounded-b-none"
        )}
        style={displayMode === "cover" ? { maxHeight: 400 } : {}}
      >
        {imageToShow ? (
          <img
            className={classNames(
              "rounded-lg object-cover w-full h-full no-translate",
              includeTicketDetails && "rounded-b-none"
            )}
            src={imageToShow}
            alt={film_name || name}
          />
        ) : null}
        {(displayMode === "poster" && !poster_image) ||
        (displayMode === "cover" && !cover_image) ? (
          <div className="font-semibold text-always-white p-4 text-center w-full no-translate">
            {film_name || name}
          </div>
        ) : null}
        {detailPopover}
        {displayMode === "default" || displayMode === "cover" ? (
          <div
            className={classNames(
              "absolute bottom-0 rounded-lg rounded-t-none w-full text-always-white font-bold py-2 px-3 leading-5 no-translate",
              displayMode === "cover" && "text-center text-lg py-4",
              includeTicketDetails && "rounded-b-none"
            )}
            style={{ backgroundColor: "rgba(0,0,0,0.5)" }}
          >
            {(isDiscoverySearch || isDiscoveryList) && type === "screening" ? (
              <div className="text-xs font-normal leading-4 mb-1 no-translate">
                {tenant_name}
              </div>
            ) : null}
            {film_name || name}
            {virtual_content_type === "livestream" ? (
              <div className="text-xs font-normal no-translate">
                {moment(start_time).format("LLL")}
              </div>
            ) : null}
          </div>
        ) : null}
        {liveBadge}
      </div>
    );
  }

  const [hoverTimer, setHoverTimer] = useState(null);
  const hoverHandler =
    (displayMode === "default" || displayMode === "poster") && onHover;
  let additionalProps;
  if (hoverHandler) {
    additionalProps = {
      onMouseEnter: () => {
        setHoverTimer(
          setTimeout(() => {
            hoverHandler();
          }, 250)
        );
      },
      onMouseLeave: () => {
        clearTimeout(hoverTimer);
      }
    };
  }

  let hrefFestivalId = `/${festivalId}`;
  let linkHref = (type === "channel")
    ? "/[festivalId]"
    : "/[festivalId]/play/[...programId]";

  if (festivalId === "sundance-film-festival-2025") {
    hrefFestivalId = "https://festivalplayer.sundance.org/sundance-film-festival-2025";
    linkHref = (type === "channel")
      ? hrefFestivalId
      : `${hrefFestivalId}/play/[...programId]`;
  }

  let asHref = (type === "channel")
    ? `${hrefFestivalId}`
    : `${hrefFestivalId}/play/${
        event_id ? [event_id, film_id].join("/") : id
      }`;

  return (
    <div
      className={classNames(
        "focus:outline-none group",
        displayMode !== "giant_slider" || isSingle ? "p-2" : "p-2 sm:p-0",
        displayMode === "cover" && "h-full"
      )}
      {...additionalProps}
    >
      <Link
        href={linkHref}
        as={asHref}
        passHref={true}
      >
        <a
          onClick={e => {
            if (hoverHandler && window.innerWidth <= 640) {
              e.preventDefault();
              hoverHandler();
            }
            if (onClick) {
              onClick();
            }
          }}
        >
          {inner}
          {includeTicketDetails ? (
            <div className="-mt-2 transform z-0 transition-transform duration-150 sm:group-hover:translate-y-2 sm:group-hover:scale-105 text-white pt-5 px-3 pb-3 bg-gray-900 rounded-b-lg text-xs">
              <div className="truncate">
                <span className="uppercase text-gray-400 font-bold mr-2">
                  Channel
                </span>
                <span className="font-medium no-translate">
                  {item.tenant_name}
                </span>
              </div>
              {item.coming_soon ? (
                <>
                  <div className="truncate">
                    <span className="uppercase text-gray-400 font-bold mr-2">
                      Pre-ordered on
                    </span>
                    <span className="font-medium no-translate">
                      {moment(item.unlocked_at).format("LLL")}
                    </span>
                  </div>
                  <div className="truncate">
                    <span className="uppercase text-gray-400 font-bold mr-2">
                      Available on
                    </span>
                    <span className="font-medium no-translate">
                      {moment(item.start_time).format("LLL")}
                    </span>
                  </div>
                </>
              ) : (
                <>
                  <div className="truncate">
                    <span className="uppercase text-gray-400 font-bold mr-2">
                      Unlocked on
                    </span>
                    <span className="font-medium no-translate">
                      {moment(item.unlocked_at).format("LLL")}
                    </span>
                  </div>
                  <div className="truncate">
                    <span className="uppercase text-gray-400 font-bold mr-2">
                      Available until
                    </span>
                    <span className="font-medium no-translate">
                      {moment(item.expires_at || item.unlocked_until).format(
                        "LLL"
                      )}
                    </span>
                  </div>
                </>
              )}
            </div>
          ) : null}
        </a>
      </Link>
    </div>
  );
}
